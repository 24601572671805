import * as React from "react";
import CookieConsent from "react-cookie-consent";

const CookiesBar = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Zgadzam się na cookies"
      cookieName="ConsentCookie"
      containerClasses="bg-light"
      buttonClasses="btn-secondary"
      style={{ color: "unset", fontSize: "11px" }}
      buttonStyle={{ backgroundColor: "#dadada", margin: "4px 15px 4px 15px" }}
      contentStyle={{ margin: "4px 15px 4px 15px" }}
    >
      Wykorzystujemy pliki cookies, żeby zbierać dane o liczbie odwiedzin. Korzystając ze strony zgadzasz się na ich użycie.
    </CookieConsent>
  )
};

export default CookiesBar;