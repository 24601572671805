import * as React from "react";
import { Nav, Navbar } from "react-bootstrap";
import style from "./topNavigation.module.scss";

const TopNavigation = () => {
  return (
    <Navbar bg="light" expand="md" sticky="top">
      <Navbar.Brand href="/" className={style.navigationLogo}>onvo</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/" className="ml-auto">Strona główna</Nav.Link>
          <Nav.Link href="/dzierzawa-krok-po-kroku" className="ml-auto">Dzierżawa krok po kroku</Nav.Link>
          <Nav.Link href="/jak-to-dziala" className="ml-auto">Jak to działa</Nav.Link>
          <Nav.Link href="/faq" className="ml-auto">FAQ - Twoje pytania</Nav.Link>
          <Nav.Link href="/kim-jestesmy" className="ml-auto">Kim jesteśmy</Nav.Link>
          <Nav.Link href="/kontakt" className="ml-auto">Kontakt</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
};

export default TopNavigation;