import * as React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import TopNavigation from "./topNavigation";
import CookiesBar from "./cookiesBar";

const constrainedWidth = {
  maxWidth: "960px",
  marginBottom: "40px",
};

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="https://use.typekit.net/siz1jlp.css" />
      </Helmet>
      <main>
        <TopNavigation />
        <Container style={constrainedWidth}>
          {children}
        </Container>
        <CookiesBar />
      </main>
    </>
  )
};

export default Layout;